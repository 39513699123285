.image-container{
    height:100%;
    width:100%;
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.image-content-cards{
    height:250px;
    margin:10px 10px;
    width:250px;
    border-radius:10px;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1),
    -1px -1px 5px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: white;
    position: relative;
}
@media (max-width:600px){
    .image-content-cards{
        height:150px !important;
        width:150px !important;
        /* border:2px solid black; */
    }
}
.image-content-card-image{
   height:80%;
   width:100%;
   margin:0 auto;
   border-radius: 10px;
   border-bottom-left-radius: 0px;
   border-bottom-right-radius: 0px;
   /* border:1px solid black; */
}
@media (max-width:600px){
    .image-content-card-image{
        height:80% !important;
        /* width:100px; */
    }
}
.image-content-card-image img{
    height:100%;
    width:100%;
    /* border:5px solid white; */
    border-radius: 10px;
    object-fit: cover;
    /* margin-left: px; */
    /* margin:5px auto; */
}
.image-content-card-footer{
    height:10%;
    width:98%;
    margin:0 auto;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-content-card-footer p{
    font-size: smaller;
}

/* .full-container{
    height:100%;
    width: 100%;
     border:2px solid green; 
    display: flex;
    flex-direction: column;
    cursor: pointer;
} */
/* .image-content-cards input:checked + label > .image-content-card-image{
    border:5px solid #551fff;
    border-radius: 10px;
    /* border-bottom: none; 
} 
/* .image-content-cards input:checked + label > .image-content-card-footer{
    border:5px solid #404047;
    border-top:none;
} */
.image-content-cards  .upper-layer{
    position: absolute;
    cursor: pointer;
    transition: all .2s ease-in-out;
    z-index: 100000000000;
}

.upper-layer::after{
    position: absolute;
    content: '';
    height: 22px;
    width:22px;
    top:8px;
    left: 8px;
    display: none;
    border:1px solid var(--primary-color);
    color:white;
    text-align: center;
    line-height: 18px;
    font-weight: 600;
    font-size: 17px;
    border-radius: 50%;
}

 input:checked + .upper-layer::after{
    position: absolute;
    content: '\2713';
    height: 22px;
    width:22px;
    top:8px;
    left: 8px;
    background-color: var(--primary-color);
    z-index: 100000000000;
    color:white;
    text-align: center;
    line-height: 18px;
    font-weight: 600;
    font-size: 17px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
input:checked + .upper-layer{
    /* height: 5vh; */
    height:5vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(165, 46, 46, 0.5);
}
.upper-layer{
    position: absolute;
    height: 0vh;
    width:250px;
    cursor: pointer;
    transition: all .2s ease;
    z-index: 100000000000;
}

.image-content-cards:hover .upper-layer{
    height:5vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(165, 46, 46, 0.5);
}

@media (max-width:600px){
    .image-content-cards .upper-layer{
          position: absolute;
          cursor: pointer;
          transition: all .2s ease-in-out;
          z-index: 100000000000;
      }
      
      .upper-layer::after{
          position: absolute;
          content: '';
          height: 22px;
          width:22px;
          top:8px;
          left: 8px;
          display: none;
          border:1px solid var(--primary-color);
          color:white;
          text-align: center;
          line-height: 18px;
          font-weight: 600;
          font-size: 17px;
          border-radius: 50%;
      }
      
      input:checked + .upper-layer::after{
          position: absolute;
          content: '\2713';
          height: 22px;
          width:22px;
          top:8px;
          left: 8px;
          background-color: var(--primary-color);
          z-index: 100000000000;
          color:white;
          text-align: center;
          line-height: 18px;
          font-weight: 600;
          font-size: 17px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      input:checked + .upper-layer{
          /* height: 5vh; */
          height:5vh;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-color: rgba(165, 46, 46, 0.5);
      }
      .upper-layer{
          position: absolute;
          height: 0vh;
          width:150px !important;
          cursor: pointer;
          transition: all .2s ease;
          z-index: 100000000000;
      }
      
      .image-content-cards:hover .upper-layer{
          height:5vh;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-color: rgba(165, 46, 46, 0.5);
      }
  }