.playlist-container {
  /* min-height:70vh; */
  /* max-width: 75vw; */
  /* border:1px solid black; */
  margin: 30px 0;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  /* max-width:100%; */
}
.user-add-btn {
  width: 273px;
  height: 58px;
  /* border:1px solid black; */
  margin: 0 20px;
}

.user-add-btn button {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  background: #39bf59;
  border-radius: 6px;
  color: white;
  cursor: pointer;
}

.content-all-delete-btn {
  width: 100% !important;
}

.playlist-schedule-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  gap: 5px;
  /* border:1px solid black; */
  top: 0;
  left: 60px;
}
.playlist-cards {
  min-height: 60vh;
  /* height: 65vh; */
  width: 400px;
  min-width: 48%;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1),
    -1px -1px 5px 0 rgba(0, 0, 0, 0.1);
  margin: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 16px;
}
.playlist-cards-top {
  min-height: 55vh;
  width: 100%;
  /* border:1px solid black; */
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  /* position: relative; */
}
.playlist-cards-bottom {
  height: 5vh;
  width: 90%;
  /* margin: 0px auto; */
  /* border:1px solid black; */
  display: flex;
  margin: 22px 0;
}

.playlist-cards .playlist-card-body {
  max-height: 50vh;
  max-width: 100%;
  /* margin: 20px auto; */
  overflow: hidden;
  overflow-y: auto;
  /* border:1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  /* margin: 20px 10px; */
}

.playlist-card-header {
  height: 10vh;
  width: 500px;
  margin: 0px auto;
  border-bottom: 1px solid black;
  display: flex;
}

.playlist-card-header-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.playlist-card-header-left {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-playlist-model {
  /* height: 30vh; */
  /* min-width: 30vw; */
  /* border:1px solid black; */
  background-color: white;
}

.create-playlist-model img {
  width: 80px;
  height: 52px;
  margin: 0px auto;
}

.create-playlist-input-field,
.create-playlist-btn-group {
  height: 7vh;
  width: 25vw;
  margin: 10px auto;
  /* border:1px solid black; */
}

.schedule-save-btn {
  width: 100px !important;
  color: white !important;
  background: var(--primary-color) !important;
}
.schedule-save-btn:focus{
  color: white !;
  background: var(--primary-color) !important;
}
@media (max-width: 600px) {
  .create-playlist-input-field,
  .create-playlist-btn-group {
    width: 50dvw;
  }
}

.btn-cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 108px;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  color: var(--primary-color);
}

.btn-save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 109px;
  background: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  margin: 0 10px;
  outline: none;
  cursor: pointer;
}
@media (max-width: 600px) {
  .btn-cancel,
  .btn-save {
    width: 80px !important;
    height: 25px;
  }
}
/* tabs model */
.main-tab-container {
  height: 80vh;
  /* min-width:90vw; */
  margin: 0 auto;
  border: 1px solid black;
  background-color: #f0f2f5;
}

.content-card-item {
  height: 205px;
  /* width: 47%; */
  width: 200px;
  /* margin: 5px auto; */
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1),
    -1px -1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid black; */
  position: relative;
  border-radius: 20px;
  margin: 1%;
}

.content-card-item .upper-sec {
  position: absolute;
  width: 100%;
  margin: 13px auto;
  z-index: 100000;
}
@media (max-width: 600px) {
  .content-card-item .upper-sec {
    z-index: 1;
  }
}
.delete-icon {
  height: 35px;
  width: 35px;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 10px;
  cursor: pointer;
  color: var(--primary-color);
  margin-right: 10px;
  z-index: 1000;
}

.timer {
  width: 65px;
  height: 35px;
  opacity: 0.9;
  border-radius: 8px;
  cursor: pointer;
  color: var(--primary-color);
  margin-left: 10px;
}
.timer-background {
  background: #ffffff;
}

.content-card-item .content-card-item-header {
  height: 10%;
  width: 95%;
  /* border-bottom:1px solid black; */
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.content-card-item .content-card-item-body {
  height: 100%;
  width: 100%;
  /* border-bottom: 1px solid black; */
  margin: 0 auto;
  border-radius: 20px;
}

.content-card-item-body img,
.content-card-item-body video,
.content-card-item-body iframe {
  height: 100%;
  width: 100%;
  border: 3px solid white;
  border-radius: 20px;
  object-fit: cover;
  margin-left: -2px;
  margin-top: -2px;
}

.content-card-item .content-card-item-footer {
  height: 20%;
  width: 95%;
  /* border-bottom:1px solid black; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.days-wrapper {
  min-height: 20%;
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.days-wrapper .label-btn {
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
#days-id input:checked + .label-btn {
  border: 1px solid white !important;
  background-color: var(--primary-color) !important;
  border-radius: 10px !important;
  transition: all 0.5s ease-in !important;
  color: white;
  /* border-bottom: none;   */
}

.control-area {
  background-color: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .control-area {
    display: flex;
    flex-direction: column;
  }
}

.btn-no-time-duration {
  background: white;
  padding: 8px;
  width: 130px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  margin-right: 10px;
  border-radius: 5px;
}
.btn-update-time-duration {
  background: var(--primary-color);
  padding: 8px;
  width: 130px;
  color: white;
  border: none;
  border-radius: 5px;
}
@media (max-width: 600) {
  .btn-no-time-duration,
  .btn-update-time-duration {
    width: 80px !important;
    height: 20px;
  }
}
